import { eventCreator, createEvent } from "./event-creators";
import { dispatchEvent, dispatchEvents, sendEvent, sendEvents } from "./event-senders";
import {
  isCdnDeployment,
  generateId,
  generateTimestamp,
  generateClientApplicationData,
  getAnonymousUserId,
} from "./helpers";
import {
  BaseBeanieEvent,
  BeanieEvent,
  BeanieResponse,
  ANONYMOUS_ID_VERSION,
  ClientApplicationData,
  ENVIRONMENT,
  OptionalMetadata,
  RequiredMetadata,
  SchemaData,
  TRANSPORT_OPTION,
} from "./types";

declare global {
  const DEPLOYMENT_ENV: ENVIRONMENT | undefined;

  interface XERO {
    beanie2: {
      createEvent: (
        requiredMetadata: RequiredMetadata,
        schemaData: SchemaData,
        optionalMetadata?: OptionalMetadata
      ) => BaseBeanieEvent;
      dispatchEvent: (
        event: BeanieEvent,
        environment: ENVIRONMENT,
        transport: TRANSPORT_OPTION
      ) => Promise<BeanieResponse>;
      dispatchEvents: (
        events: BeanieEvent[],
        environment: ENVIRONMENT,
        transport: TRANSPORT_OPTION
      ) => Promise<BeanieResponse>;
      // prettier-ignore
      eventCreator: (requiredMetadata: RequiredMetadata) =>
      (optionalMetadata?: OptionalMetadata) =>
      (schemaData: SchemaData) => BaseBeanieEvent;
      generateClientApplicationData: () => ClientApplicationData;
      generateId: () => string;
      generateTimestamp: () => string;
      getAnonymousUserId: (version: ANONYMOUS_ID_VERSION) => string | null;
      sendEvent: (event: BaseBeanieEvent, environment: ENVIRONMENT) => void;
      sendEvents: (events: BaseBeanieEvent[], environment: ENVIRONMENT) => void;
    };
  }

  interface Window {
    DEPLOYMENT_ENV: ENVIRONMENT;
    XERO: XERO;
  }
}

if (isCdnDeployment()) {
  window.XERO = window.XERO || { beanie2: null };

  window.XERO.beanie2 = {
    createEvent,
    dispatchEvent,
    dispatchEvents,
    eventCreator,
    generateClientApplicationData,
    generateId,
    generateTimestamp,
    getAnonymousUserId,
    sendEvent,
    sendEvents,
  };
}
