import { BaseBeanieEvent, OptionalMetadata, RequiredMetadata, SchemaData } from "../types";
import { wrapId, wrapIds } from "./helpers.creators";

/**
   * Constructs a Beanie event from various partial data or information. It functions the same way as eventCreator but with all parts of the event supplied at once.

   * This allows chunks of data to be supplied at different stages.
   *   1. Required metadata - A list of core required properties commonly shared between all Beanie schemas.
   *   2. Optional metadata - A list of core properties such as Xero User ID or Org ID that may be shared by across various schemas but are not required.
   *   3. Schema-specific data - The set of information that is unique to each schema including its fully qualified 'type' name and the event type specific data.
   *
   * The creator returns a BaseBeanieEvent type. This represents an event that has not yet occurred, and its generated metadata such as timestamp
   * and event ID have not been produced. The sendEvent function accepts a BaseBeanieEvent which will convert them into full Beanie events and dispatch them to the Beanie back-end service.
   * @param {RequiredMetadata} requiredMetadata A list of core required properties commonly shared between different Beanie schemas.
   * @param {OptionalMetadata} optionalMetadata A list of core properties such as Xero User ID or Org ID that may be shared by across various schemas but are not required.
   * @param {SchemaData} schemaData The set of information that is unique to each schema including its fully qualified 'type' name and the event type specific data. For the 'data' property, you may use @xero/beanie-schemas to search your specific type/interfaces.
   * @returns {BaseBeanieEvent} A subset of the BeanieEvent interface without the generated properties such as ID, timestamp, and client application data.
   */
export const createEvent = (
  requiredMetadata: RequiredMetadata,
  schemaData: SchemaData,
  optionalMetadata?: OptionalMetadata
): BaseBeanieEvent => {
  return {
    source: requiredMetadata.source,
    type: schemaData.type,
    userBehaviourProperties: {
      category: requiredMetadata.category,
      productArea: requiredMetadata.productArea,
      sourceDomain: requiredMetadata.sourceDomain,
      // prettier-ignore
      ...(optionalMetadata?.organisationIdList && { organisationIdList: wrapIds(optionalMetadata.organisationIdList) }),
      ...(optionalMetadata?.practiceIdList && {
        practiceIdList: wrapIds(optionalMetadata.practiceIdList),
      }),
      ...(optionalMetadata?.entityIdList && {
        entityIdList: wrapIds(optionalMetadata.entityIdList),
      }),
    },
    ...(optionalMetadata?.userId && { userId: wrapId(optionalMetadata.userId) }),
    ...(optionalMetadata?.hubdocUserId && { hubdocUserId: wrapId(optionalMetadata.hubdocUserId) }),
    ...(optionalMetadata?.workflowmaxUserId && {
      workflowmaxUserId: wrapId(optionalMetadata.workflowmaxUserId),
    }),
    ...(optionalMetadata?.plandayUserId && { plandayUserId: wrapId(optionalMetadata.plandayUserId) }),
    ...(optionalMetadata?.organisationId && {
      organisationId: wrapId(optionalMetadata.organisationId),
    }),
    ...(optionalMetadata?.practiceId && { practiceId: wrapId(optionalMetadata.practiceId) }),
    ...(schemaData.data && { data: schemaData.data }),
  };
};

/**
 * Constructs a Beanie event from various partial data or information. An Event Creator is not strictly needed to send an event to the Beanie pipeline;
 * it is intended to assist producer in creating an event with the right structure and values in place.
 *
 * The function is curried with three parts of a Beanie event.
 * This allows chunks of data to be supplied at different stages.
 *   1. Required metadata - A list of core required properties commonly shared between all Beanie schemas.
 *   2. Optional metadata - A list of core properties such as Xero User ID or Org ID that may be shared by across various schemas but are not required.
 *   3. Schema-specific data - The set of information that is unique to each schema including its fully qualified 'type' name and the event type specific data.
 *
 * The creator returns a BaseBeanieEvent type. This represents an event that has not yet occurred, and its generated metadata such as timestamp
 * and event ID have not been produced. The sendEvent function accepts a BaseBeanieEvent which will convert them into full Beanie events and dispatch them to the Beanie back-end service.
 * @param {RequiredMetadata} requiredMetadata A list of core required properties commonly shared between different Beanie schemas.
 * @param {OptionalMetadata} optionalMetadata A list of core properties such as Xero User ID or Org ID that may be shared by across various schemas but are not required.
 * @param {SchemaData} schemaData The set of information that is unique to each schema including its fully qualified 'type' name and the event type specific data. For the 'data' property, you may use @xero/beanie-schemas to search your specific type/interfaces.
 * @returns {BaseBeanieEvent} A subset of the BeanieEvent interface without the generated properties such as ID, timestamp, and client application data.
 */
export const eventCreator =
  (requiredMetadata: RequiredMetadata) =>
  (optionalMetadata?: OptionalMetadata) =>
  (schemaData: SchemaData): BaseBeanieEvent =>
    createEvent(requiredMetadata, schemaData, optionalMetadata);
