import { BeanieEvent } from "./events";

/**
 * The Beanie 2.0 API '/events' endpoint request interface.
 */
export interface BeanieRequest {
  events: BeanieEvent[];
}

/**
 * The Beanie 2.0 API '/events' endpoint response interface.
 */
export interface BeanieResponse {
  code: number;
  numEventsAccepted: number;
  numEventsSent: number;
  numEventsFailed: number;
  message: string;
  eventResults: EventResult[];
}

/**
 * Event specific validation result from the Beanie 2.0 API '/events' endpoint.
 */
export interface EventResult {
  id: string;
  type: string;
  message: string;
}

/**
 * Options for the underlying transport implementation of the Event Sender dispatchEvent and dispatchEvents functions.
 */
export enum TRANSPORT_OPTION {
  FETCH_API = "fetch",
  SEND_BEACON = "sendBeacon",
  XHR = "xhr",
}
