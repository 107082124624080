/**
 * The base endpoint (without trailing forward slash) of the Beanie BFF service for the 'Test' environment.
 */
export const BEANIE_ENDPOINT_TEST: string = "https://product-analytics-bff.xero-test.com";

/**
 * The base endpoint (without trailing forward slash) of the Beanie BFF service for the 'UAT' environment.
 */
export const BEANIE_ENDPOINT_UAT: string = "https://product-analytics-bff.xero-uat.com";

/**
 * The base endpoint (without trailing forward slash) of the Beanie BFF service for the 'Production' environment.
 */
export const BEANIE_ENDPOINT_PROD: string = "https://product-analytics-bff.xero.com";

/**
 * The current /events endpoint version that this library targets.
 */
export const BEANIE_ENDPOINT_VERSION: string = "v2.0";
