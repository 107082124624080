/** @license Apache-2.0 which includes code portions from mixpanel.js: https://github.com/mixpanel/mixpanel-js/blob/master/LICENSE **/

const includes = (str: string, needle: string) => str.indexOf(needle) !== -1;

/**
 * Extracts the domain including top-level domain and subdomain from a URL string.
 * For example: 'https://github.com/npm/cli' -> 'github.com'.
 * @param {string} referrer The referrer full URL as a string.
 * @returns {string} A string containing only the domain part of the URL.
 */
export const getReferringDomain = (referrer: string): string => {
  const split = referrer.split("/");
  // A valid referrer URL has to have at least the scheme. e.g. https://google.com
  // https://html.spec.whatwg.org/multipage/dom.html#the-document's-referrer
  if (includes(referrer, "//") && split.length >= 3) {
    return split[2];
  }
  return "";
};

/**
 * Detects which browser is running this script.
 * The order of the checks are important since many user agents
 * include key words used in later checks.
 * @param {string} userAgent The User-Agent string for the current browser.
 * @param {string} vendor Either an empty string, the string "Apple Computer, Inc.", or the string "Google Inc.".
 * @param {string} opera The old pre-chromium Opera versions provide an 'opera' object.
 * @returns {string} The browser information from the User-Agent.
 */
export const getBrowserInformation = (userAgent: string, vendor: string, opera: any): string => {
  const nullSafeVendor = vendor || ""; // vendor is undefined for at least IE9
  if (opera || includes(userAgent, " OPR/") || includes(userAgent, "Opera/")) {
    if (includes(userAgent, "Mini")) {
      return "Opera Mini";
    }
    return "Opera";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (includes(userAgent, "IEMobile") || includes(userAgent, "WPDesktop")) {
    return "Internet Explorer Mobile";
  } else if (includes(userAgent, "SamsungBrowser/")) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return "Samsung Internet";
  } else if (includes(userAgent, "Edge") || includes(userAgent, "Edg/")) {
    return "Microsoft Edge";
  } else if (includes(userAgent, "UCWEB") || includes(userAgent, "UCBrowser")) {
    return "UC Browser";
  } else if (includes(userAgent, "FBIOS")) {
    return "Facebook Mobile";
  } else if (includes(userAgent, "Chrome")) {
    return "Chrome";
  } else if (includes(userAgent, "CriOS")) {
    return "Chrome iOS";
  } else if (includes(userAgent, "FxiOS")) {
    return "Firefox iOS";
  } else if (includes(nullSafeVendor, "Apple")) {
    if (includes(userAgent, "Mobile")) {
      return "Mobile Safari";
    }
    return "Safari";
  } else if (includes(userAgent, "Android")) {
    return "Android Mobile";
  } else if (includes(userAgent, "Konqueror")) {
    return "Konqueror";
  } else if (includes(userAgent, "Firefox")) {
    return "Firefox";
  } else if (includes(userAgent, "MSIE") || includes(userAgent, "Trident/")) {
    return "Internet Explorer";
  } else if (includes(userAgent, "Gecko")) {
    return "Mozilla";
  } else {
    return "";
  }
};

/**
 * Detects which browser version is running this script,
 * parsing major and minor version (e.g., 42.1). User agent strings from:
 * http://www.useragentstring.com/pages/useragentstring.php
 * @param {string} userAgent The User-Agent string for the current browser.
 * @param {string} vendor Either an empty string, the string "Apple Computer, Inc.", or the string "Google Inc.".
 * @param {string} opera The old pre-chromium Opera versions provide an 'opera' object.
 * @returns {string} The browser version information from the User-Agent.
 */
export const getBrowserVersion = (userAgent: string, vendor: string, opera: any): string => {
  const browserInfo: string = getBrowserInformation(userAgent, vendor, opera);
  const versionRegexs: { [key: string]: RegExp } = {
    "Android Mobile": /android\s(\d+(\.\d+)?)/,
    BlackBerry: /BlackBerry (\d+(\.\d+)?)/,
    Chrome: /Chrome\/(\d+(\.\d+)?)/,
    "Chrome iOS": /CriOS\/(\d+(\.\d+)?)/,
    Firefox: /Firefox\/(\d+(\.\d+)?)/,
    "Firefox iOS": /FxiOS\/(\d+(\.\d+)?)/,
    "Internet Explorer": /(rv:|MSIE )(\d+(\.\d+)?)/,
    "Internet Explorer Mobile": /rv:(\d+(\.\d+)?)/,
    Konqueror: /Konqueror:(\d+(\.\d+)?)/,
    "Microsoft Edge": /Edge?\/(\d+(\.\d+)?)/,
    "Mobile Safari": /Version\/(\d+(\.\d+)?)/,
    Mozilla: /rv:(\d+(\.\d+)?)/,
    Opera: /(Opera|OPR)\/(\d+(\.\d+)?)/,
    Safari: /Version\/(\d+(\.\d+)?)/,
    "Samsung Internet": /SamsungBrowser\/(\d+(\.\d+)?)/,
    "UC Browser": /(UCBrowser|UCWEB)\/(\d+(\.\d+)?)/,
  };
  const regex = versionRegexs[browserInfo];
  if (regex === undefined) {
    return "";
  }
  const matches = userAgent.match(regex);
  if (!matches) {
    return "";
  }

  const version: string = matches[matches.length - 2];
  return version;
};

/**
 * Extracts the mobile device information from
 * the User-Agent if a mobile browser is used.
 * @param {string} userAgent The User-Agent string for the current browser.
 * @returns {string} The device information from the User-Agent.
 */
export const getDeviceInformation = (userAgent: string): string => {
  if (/Windows Phone/i.test(userAgent) || /WPDesktop/.test(userAgent)) {
    return "Windows Phone";
  } else if (/iPad/.test(userAgent)) {
    return "iPad";
  } else if (/iPod/.test(userAgent)) {
    return "iPod Touch";
  } else if (/iPhone/.test(userAgent)) {
    return "iPhone";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else {
    return "";
  }
};

/**
 * Extracts the operating system information of the browser from the User-Agent.
 * @param {string} userAgent The User-Agent string for the current browser.
 * @returns {string} The OS information from the User-Agent.
 */
export const getOperatingSystemInfo = (userAgent: string): string => {
  if (/Windows/i.test(userAgent)) {
    if (/Phone/.test(userAgent) || /WPDesktop/.test(userAgent)) {
      return "Windows Phone";
    }
    return "Windows";
  } else if (/(iPhone|iPad|iPod)/.test(userAgent)) {
    return "iOS";
  } else if (/Android/.test(userAgent)) {
    return "Android";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(userAgent)) {
    return "BlackBerry";
  } else if (/Mac/i.test(userAgent)) {
    return "Mac OS X";
  } else if (/Linux/.test(userAgent)) {
    return "Linux";
  } else if (/CrOS/.test(userAgent)) {
    return "Chrome OS";
  } else {
    return "";
  }
};

// isBlockedUA()
// This is to block various web spiders from executing our JS and
// sending false tracking data
const BLOCKED_UA_STRS: string[] = [
  "ahrefsbot",
  "baiduspider",
  "bingbot",
  "bingpreview",
  "facebookexternal",
  "petalbot",
  "pinterest",
  "screaming frog",
  "yahoo! slurp",
  "yandexbot",

  // a whole bunch of goog-specific crawlers
  // https://developers.google.com/search/docs/advanced/crawling/overview-google-crawlers
  "adsbot-google",
  "apis-google",
  "duplexweb-google",
  "feedfetcher-google",
  "google favicon",
  "google web preview",
  "google-read-aloud",
  "googlebot",
  "googleweblight",
  "mediapartners-google",
  "storebot-google",
];
export const isBlockedUA = (userAgent: string): boolean => {
  const ua: string = userAgent.toLowerCase();
  return BLOCKED_UA_STRS.some(blockedUA => ua.toLowerCase().includes(blockedUA));
};
