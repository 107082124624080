import { BEANIE_ENDPOINT_VERSION } from "../constants";
import { wrapId } from "../event-creators";
import {
  generateId,
  generateTimestamp,
  generateClientApplicationData,
  getBeanieBaseEndpoint,
  generateAnonymousUserId,
  isBlockedUA,
} from "../helpers";
import { BeanieEvent, BeanieRequest, BeanieResponse, ENVIRONMENT, BaseBeanieEvent, TRANSPORT_OPTION } from "../types";

/**
 * Dispatches events as a payload to the Beanie service of the current environment.
 * The function uses either of three options for method of transport described as follows:
 *   1. sendBeacon - It uses the Beacon API navigator.sendBeacon(). It offers no callbacks or status indications, and
 *      the response object from the Beanie API will not be available; the Promise returned will immediately be resolved
 *      or rejected depending on whether sendBeacon successfully queued the data for transfer or it has failed. It has the same data
 *      limitations as Fetch API with keepalive option, and it only supports POST with text/plain content-type.
 *      The Beacon API has a wider browser support than the Fetch API with 'keepalive' option.
 *   2. fetch - The Fetch API using the 'keepalive' option is the preferred method for when blocking calls are desirable; the option means that the request may outlive
 *      the page that initiates it. It has a benefit of returning a promise with the response object from the Beanie API.
 *      However, it has a limited browser support and several other limitations compared to sendBeacon (https://github.com/whatwg/fetch/issues/679)
 *      The request body is limited to 64kb. The data limit applies to all keepalive requests together. Therefore, the sum of the body lengths of
 *      multiple requests performed in parallel cannot exceeds 64kb.
 *   3. xhr - The XMLHttpRequest will be used as a fallback if the previous two methods are unavailable. It is not
 *      suitable for situations such as when the browser is about to unload the page (e.g. navigating to another page).
 *      In this case, the browser may choose not to send asynchronous xhr requests.
 * @param {BeanieEvent[]} events The list of Beanie events to be sent.
 * @param {ENVIRONMENT} environment The Beanie environment to send the events to.
 * @param {TRANSPORT_OPTION} transport An option on whether to send the events with the default
 *     navigator.sendBeacon (no callbacks or status indications),
 *     fetch API with 'keepalive' = true option (limited browser support),
 *     or with XMLHttpRequest (may be interrupted on unload).
 * @returns {Promise<BeanieResponse>} A Promise object of type BeanieResponse.
 *     The promise is immediately resolved or rejected if the transport option selected is sendBeacon.
 */
export const dispatchEvents = async (
  events: BeanieEvent[],
  environment: ENVIRONMENT,
  transport: TRANSPORT_OPTION = TRANSPORT_OPTION.SEND_BEACON
): Promise<BeanieResponse> => {
  // check if the userAgent is in the blocked list, this is blocking bots
  if (isBlockedUA(navigator.userAgent)) {
    const res: BeanieResponse = {
      code: 0,
      eventResults: [],
      message: "UserAgent Blocked",
      numEventsAccepted: 0,
      numEventsFailed: 0,
      numEventsSent: 0,
    };
    return Promise.resolve<BeanieResponse>(res);
  }

  const baseEndpoint = getBeanieBaseEndpoint(environment);
  const endpoint = `${baseEndpoint}/${BEANIE_ENDPOINT_VERSION}/events`;
  const req: BeanieRequest = { events: events };
  const payload = JSON.stringify(req);

  if (transport === TRANSPORT_OPTION.SEND_BEACON && navigator.sendBeacon) {
    // sendBeacon does not return a response status.
    const res: BeanieResponse = {
      code: 0,
      eventResults: [],
      message: "",
      numEventsAccepted: events.length,
      numEventsFailed: 0,
      numEventsSent: 0,
    };
    let succeeded: boolean;
    try {
      succeeded = navigator.sendBeacon(endpoint, payload);
      res.message = succeeded
        ? "sendBeacon has successfully queued the data for transfer. Status unknown."
        : "sendBeacon has failed. Status unknown.";
    } catch (e) {
      res.message = `sendBeacon has failed. Status unknown. ${e}`;
      succeeded = false;
    }

    return succeeded ? Promise.resolve<BeanieResponse>(res) : Promise.reject<BeanieResponse>(res);
  } else if (transport === TRANSPORT_OPTION.FETCH_API) {
    return fetch(endpoint, {
      body: payload,
      headers: { "Content-Type": "text/plain" },
      keepalive: true,
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => data as BeanieResponse);
  } else {
    return new Promise<BeanieResponse>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "json";
      xhr.open("POST", endpoint, true);
      xhr.onload = () => {
        resolve(xhr.response as BeanieResponse);
      };
      xhr.onerror = () => {
        const res: BeanieResponse = {
          code: xhr.status,
          eventResults: [],
          message: `XMLHttpRequest error: ${xhr.status} - ${xhr.statusText}`,
          numEventsAccepted: events.length,
          numEventsFailed: events.length,
          numEventsSent: 0,
        };
        reject(res);
      };
      // Send as plain text to match sendBeacon.
      xhr.setRequestHeader("Content-Type", "text/plain");
      xhr.send(payload);
    });
  }
};

/**
 * Sends a list of events to the Beanie service. It accepts a list of BaseBeanieEvent prepared by an Event Creator function.
 * A BaseBeanieEvent is an event that has not yet occurred, and its generated metadata such as event ID, timestamp, and client/browser info are not yet included.
 * This sendEvent function completes the base events into BeanieEvent type by adding ID, timestamp, and browser information.
 * All events in the batch will have the same values for 'time' and 'client application data'.
 * @param {BaseBeanieEvent[]} events A list of BaseBeanieEvent objects.
 * @param {ENVIRONMENT} environment The Beanie environment to send the events to.
 */
export const sendEvents = (events: BaseBeanieEvent[], environment: ENVIRONMENT) => {
  // Generate time and client application data beforehand to make them uniform across the events in the batch.
  const time = generateTimestamp();
  const appData = generateClientApplicationData();
  const beanieEvents: BeanieEvent[] = [];

  for (const ev of events) {
    // Convert to a complete Beanie event.
    const anonymousUserId = generateAnonymousUserId(
      {
        entityIdList: ev.userBehaviourProperties.entityIdList?.map((x) => x.value),
        hubdocUserId: ev.hubdocUserId?.value,
        organisationId: ev.organisationId?.value,
        plandayUserId: ev.plandayUserId?.value,
        practiceId: ev.practiceId?.value,
        practiceIdList: ev.userBehaviourProperties.practiceIdList?.map((x) => x.value),
        userId: ev.userId?.value,
        workflowmaxUserId: ev.workflowmaxUserId?.value,
      },
      environment
    );
    const beanieEvent: BeanieEvent = {
      ...ev,
      ...(anonymousUserId && { anonymousUserId: wrapId(anonymousUserId) }),
      clientApplication: appData,
      id: generateId(),
      time: time,
    };

    beanieEvents.push(beanieEvent);
  }

  try {
    // Defaults to use the Beacon API
    dispatchEvents(beanieEvents, environment, TRANSPORT_OPTION.SEND_BEACON);
  } catch {
    // Do nothing - prevent errors from bubbling up and crashing the consumer.
  }
};

/**
 * Dispatches an event as a payload to the Beanie service of the current environment.
 * The function uses either of three options for method of transport described as follows:
 *   1. sendBeacon - It uses the Beacon API navigator.sendBeacon(). It offers no callbacks or status indications, and
 *      the response object from the Beanie API will not be available; the Promise returned will immediately be resolved
 *      or rejected depending on whether sendBeacon successfully queued the data for transfer or it has failed. It has the same data
 *      limitations as Fetch API with keepalive option, and it only supports POST with text/plain content-type.
 *      The Beacon API has a wider browser support than the Fetch API with 'keepalive' option.
 *   2. fetch - The Fetch API using the 'keepalive' option is the preferred method for when blocking calls are desirable; the option means that the request may outlive
 *      the page that initiates it. It has a benefit of returning a promise with the response object from the Beanie API.
 *      However, it has a limited browser support and several other limitations compared to sendBeacon (https://github.com/whatwg/fetch/issues/679)
 *      The request body is limited to 64kb. The data limit applies to all keepalive requests together. Therefore, the sum of the body lengths of
 *      multiple requests performed in parallel cannot exceeds 64kb.
 *   3. xhr - The XMLHttpRequest will be used as a fallback if the previous two methods are unavailable. It is not
 *      suitable for situations such as when the browser is about to unload the page (e.g. navigating to another page).
 *      In this case, the browser may choose not to send asynchronous xhr requests.
 * @param {BeanieEvent} event The Beanie event to be sent.
 * @param {ENVIRONMENT} environment The Beanie environment to send the event to.
 * @param {TRANSPORT_OPTION} transport An option on whether to send the event with the default
 *     fetch API, navigator.sendBeacon (no callbacks or status indications), or with XMLHttpRequest (may be interrupted on unload).
 * @returns {Promise<BeanieResponse>} A Promise object of type BeanieResponse.
 *     The promise is immediately resolved or rejected if the transport option selected is sendBeacon.
 */
export const dispatchEvent = async (
  event: BeanieEvent,
  environment: ENVIRONMENT,
  transport: TRANSPORT_OPTION = TRANSPORT_OPTION.SEND_BEACON
): Promise<BeanieResponse> => dispatchEvents([event], environment, transport);

/**
 * Sends an event to the Beanie service. It accepts a list of BaseBeanieEvent prepared by an Event Creator function.
 * A BaseBeanieEvent is an event that has not yet occurred, and its generated metadata such as event ID, timestamp, and client/browser info are not yet included.
 * This sendEvent function completes the base events into BeanieEvent type by adding ID, timestamp, and browser information.
 * All events in the batch will have the same values for 'time' and 'client application data'.
 * @param {BaseBeanieEvent} event A BaseBeanieEvent object.
 * @param {ENVIRONMENT} environment The Beanie environment to send the event to.
 */
export const sendEvent = (event: BaseBeanieEvent, environment: ENVIRONMENT) => sendEvents([event], environment);
