import { v4 as uuid } from "uuid";

import { BEANIE_LIBRARY_MONIKER, BEANIE_LIBRARY_VERSION } from "../constants";
import { eventCreator, wrapId } from "../event-creators";
import { dispatchEvent } from "../event-senders";
import { ANONYMOUS_ID_VERSION, BeanieEvent, ClientApplicationData, ENVIRONMENT, OptionalMetadata } from "../types";
import {
  getBrowserInformation,
  getBrowserVersion,
  getDeviceInformation,
  getOperatingSystemInfo,
  getReferringDomain,
} from "./ua.helpers";
import { getUTMParameters } from "./utm.helpers";

/**
 * Find out if user has LocalStorage enabled
 * @returns {boolean} Boolean value representing if the user has LocalStorage enabled.
 */
export const hasLocalStorage = (): boolean => {
  const mod = 'beanie';
  try {
    window.localStorage.setItem(mod, mod);
    window.localStorage.removeItem(mod);
    return true;
  } catch (e) {
    if (e instanceof DOMException) {
      return false;
    } else {
      throw e;
    }
  }
}

/**
 * Generates an event identifier. Currently, it is a wrapper of the generate v4 UUID.
 * @returns {string} A UUID which represents a random ID.
 */
export const generateId = (): string => {
  return uuid();
};

/**
 * Find the existing anonymous user ID from the LocalStorage for the given version.
 *
 * @param {ANONYMOUS_ID_VERSION} version a version for Beanie JS Library.
 * @returns an anonymous user id generated by the given version of Beanie JS Library.
 */
export const getAnonymousUserId = (version: ANONYMOUS_ID_VERSION): string | null => {

  let existingBeanieAnonymousUserIdItem = null;
  if(hasLocalStorage()) {
    existingBeanieAnonymousUserIdItem = window.localStorage.getItem(version);
  }

  if (!existingBeanieAnonymousUserIdItem) {
    return null;
  }

  return JSON.parse(existingBeanieAnonymousUserIdItem);
};

/**
 * Find the primary system user identifier. Xero user ID take the first precedence.
 * If there is no Xero user id, it takes the first non-null user ID from the third-party ID list.
 *
 * @param xeroUserId Xero user ID.
 * @param thirdPartyIds a list of third-party system user IDs such as workflowmax user ID, planday user ID or hubdoc user ID.
 * @returns
 */
const getPrimarySystemUserIdentifier = (xeroUserId?: string, thirdPartyIds?: (string | undefined)[]): string | null => {
  if (xeroUserId) {
    return xeroUserId;
  }
  return thirdPartyIds?.find((x) => x !== undefined) || null;
};

/**
 * Generates an event timestamp.
 * @returns {string} An ISO 8601 compliant string representation of the UTC time at the moment of function invocation.
 */
export const generateTimestamp = (): string => {
  return new Date().toISOString();
};

/**
 * Captures a set of information containing User-Agent, browser, device, campaign data, operating system, etc.
 * @returns {ClientApplicationData} An object containing application data relevant for a browser based environment.
 */
export const generateClientApplicationData = (): ClientApplicationData => {
  // Initialize variables from the browser's APIs.
  const referrer: string = document.referrer;
  const userAgent: string = navigator.userAgent;
  const vendor: string = navigator.vendor;
  // Old Opera versions (<16) provide an 'opera' object as a property of the window object.
  // It contains console access, garbage collection methods, version, and build numbers, etc.
  // After using chromium, Opera removed the object and its official doc is hard to find.
  const opera = (window as any).opera;
  const documentUrl: string = document.URL;
  const screenHeightPixels: number = window.screen.height;
  const screenWidthPixels: number = window.screen.width;
  const timezoneOffsetInHours: string = (new Date().getTimezoneOffset() / 60).toString();

  return {
    browser: {
      beanieLibrary: BEANIE_LIBRARY_MONIKER,
      beanieLibraryVersion: BEANIE_LIBRARY_VERSION,
      browser: getBrowserInformation(userAgent, vendor, opera),
      browserVersion: getBrowserVersion(userAgent, vendor, opera),
      clientHeight: screenHeightPixels,
      clientWidth: screenWidthPixels,
      currentUrl: documentUrl,
      device: getDeviceInformation(userAgent),
      operatingSystem: getOperatingSystemInfo(userAgent),
      referrer: referrer,
      referringDomain: getReferringDomain(referrer),
      timeZoneOffset: timezoneOffsetInHours,
      ...getUTMParameters(documentUrl),
    },
  };
};

/**
 * Construct a `UserLinkedEvent` based on the provided anonymous user ID and other IDs.
 * @param anonymousUserId anonymous user ID.
 * @param {OptionalMetadata} optionalMetadata contains other IDs such as workflowmax user ID, planday user ID or hubdoc user ID.
 * @returns {BeanieEvent} UserLinkedEvent type of BeanieEvent.
 */
export const constructUserLinkedEvent = (anonymousUserId: string, optionalMetadata: OptionalMetadata): BeanieEvent => {
  const time = generateTimestamp();
  const appData = generateClientApplicationData();
  return {
    ...eventCreator({
      category: "systemOutcomeEvent",
      productArea: "BEANiE",
      source: "urn:kotahi:component:mrbcVQeu2eeKCKdnbuYJ4e",
      sourceDomain: "xero-appplat-usageanalytics",
    })({
      entityIdList: optionalMetadata.entityIdList,
      hubdocUserId: optionalMetadata.hubdocUserId,
      organisationId: optionalMetadata.organisationId,
      plandayUserId: optionalMetadata.plandayUserId,
      practiceId: optionalMetadata.practiceId,
      practiceIdList: optionalMetadata.practiceIdList,
      userId: optionalMetadata.userId,
      workflowmaxUserId: optionalMetadata.workflowmaxUserId,
    })({
      type: "xero.behavioural-event.xero-appplat-usageanalytics.user.linked.v1",
    }),
    ...(anonymousUserId && { anonymousUserId: wrapId(anonymousUserId) }),
    clientApplication: appData,
    id: generateId(),
    time: time,
  };
};

/**
 * Generate or get the existing BEANiE 2.0 anonymous user ID from the LocalStorage.
 * If there is an existing BEANiE 1.0 anonymous user ID, this 2.0 library will use it.
 * If there is a user ID and a BEANiE 2.0 anonymous user ID at the same time, it initiate the identification process by sending `UserLinkedEvent`.
 * @param {OptionalMetadata} optionalMetadata optional metadata contains all different type of IDs.
 * @param {ENVIRONMENT} environment The Beanie environment to send the events to.
 * @returns anonymouse user ID.
 */
export const generateAnonymousUserId = (
  optionalMetadata: OptionalMetadata,
  environment: ENVIRONMENT
): string | null => {
  const existingBeanie2AnonymousUserId = getAnonymousUserId(ANONYMOUS_ID_VERSION.BEANIE_TWO);

  const primarySystemUserIdentifier = getPrimarySystemUserIdentifier(optionalMetadata?.userId, [
    optionalMetadata?.hubdocUserId,
    optionalMetadata?.workflowmaxUserId,
    optionalMetadata?.plandayUserId,
  ]);

  if (primarySystemUserIdentifier) {
    if (existingBeanie2AnonymousUserId) {
      // this is the first event we can initiate the identification process
      // as we know both of anonymous user id and user id
      dispatchEvent(constructUserLinkedEvent(existingBeanie2AnonymousUserId, optionalMetadata), environment);
      window.localStorage.removeItem(ANONYMOUS_ID_VERSION.BEANIE_TWO);
    }
    return null;
  }

  let anonymousUserId = null;
  if (!existingBeanie2AnonymousUserId) {
    const existingBeanie1AnonymousUserId = getAnonymousUserId(ANONYMOUS_ID_VERSION.BEANIE_ONE);
    if (existingBeanie1AnonymousUserId) {
      anonymousUserId = existingBeanie1AnonymousUserId;
    } else {
      if(hasLocalStorage()) {
        anonymousUserId = generateId();
        window.localStorage.setItem(ANONYMOUS_ID_VERSION.BEANIE_ONE, JSON.stringify(anonymousUserId));
      } else {
        anonymousUserId = null;
      }
    }
    if(hasLocalStorage()) {
        window.localStorage.setItem(ANONYMOUS_ID_VERSION.BEANIE_TWO, JSON.stringify(anonymousUserId));
    }
  } else {
    anonymousUserId = existingBeanie2AnonymousUserId;
  }

  return anonymousUserId;
};
