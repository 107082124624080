import { BEANIE_ENDPOINT_TEST, BEANIE_ENDPOINT_UAT, BEANIE_ENDPOINT_PROD } from "../constants";
import { ENVIRONMENT } from "../types";

const hasProcess = typeof process !== "undefined" && typeof process.env !== "undefined";

/**
 * Gets the deployment environment of this application.
 * @returns {ENVIRONMENT} The environment name.
 */
export const getEnv = (): ENVIRONMENT =>
  DEPLOYMENT_ENV ??
  (process.env.DEPLOYMENT_ENV as ENVIRONMENT) ??
  (hasProcess ? (process.env.NODE_ENV as ENVIRONMENT) : undefined) ??
  ENVIRONMENT.DEVELOPMENT;

/**
 * Gets the endpoint for the Beanie service.
 * @param {BEANIE_ENVIRONMENT} env The Beanie environment which the API service is in.
 * @returns {string} The base URL (without trailing forward slash) of the Beanie BFF API depending on the environment.
 *     It defaults to the 'Test' endpoint if the environment is invalid.
 */
export const getBeanieBaseEndpoint = (env: ENVIRONMENT): string => {
  switch (env) {
    case ENVIRONMENT.DEVELOPMENT:
      return BEANIE_ENDPOINT_TEST;
    case ENVIRONMENT.STAGING:
      return BEANIE_ENDPOINT_TEST;
    case ENVIRONMENT.UAT:
      return BEANIE_ENDPOINT_UAT;
    case ENVIRONMENT.PRODUCTION:
      return BEANIE_ENDPOINT_PROD;
    default:
      return BEANIE_ENDPOINT_TEST;
  }
};

/**
 * Information on whether the current runtime distribution method is CDN or NPM.
 * @returns {boolean} True if CDN, False otherwise.
 */
export const isCdnDeployment = (): boolean => {
  let isCdn: boolean;
  try {
    // $IS_CDN string is replaced with a boolean value in the rollup.js builds.
    isCdn = JSON.parse("$IS_CDN");
  } catch (e) {
    isCdn = false;
  }

  return isCdn;
};
