import { BrowserApplicationData } from "../types";

const UTM_PARAMETERS = ["utmSource", "utmMedium", "utmCampaign", "utmContent", "utmTerm"] as const;
/**
 * A part of the browser application data which contains UTM parameters.
 */
export type UTMApplicationData = Pick<BrowserApplicationData, typeof UTM_PARAMETERS[number]>;

/**
 * Gets the value of a query parameter from a specified URL's query string.
 * @param {string} url The URL to extract the query parameter from.
 * @param {string} param The query parameter key to search for.
 * @returns {string} A string containing the decoded value of the query parameter.
 * @license Apache-2.0 which includes code portions from mixpanel.js: https://github.com/mixpanel/mixpanel-js/blob/master/LICENSE
 */
export const getQueryParam = (url: string, param: string) => {
  // Expects a raw URL
  // RegExp reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#syntax
  // Escape square brackets due to them being a valid regex character.
  const escapedParam = param.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  // This is a regex matching one query parameter.
  // It terminates with '&' at the end because it marks the start of the next query string parameter.
  // It also terminates with '#' which marks URL fragments at the end of the URL if there is any because fragments are not a part of the value of the parameter.
  // e.g. url: www.mywebsite.com/somepage.aspx?utm_source=source&utm_medium=testMedium#productnamesection
  //      param: utm_medium
  //      output: testMedium
  const regexS: string = "[\\?&]" + escapedParam + "=([^&#]*)";
  const regex: RegExp = new RegExp(regexS);
  const results: RegExpExecArray | null = regex.exec(url);

  // results[1] contains The parenthesized substring matches, if any.
  // Reference: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
  if (results === null || (results && typeof results[1] !== "string")) {
    return "";
  } else {
    let result: string = results[1];
    try {
      // Decode the component from its raw URL source to a more human-readable string.
      result = decodeURIComponent(result);
    } catch (err) {
      console.error("Skipping decoding for malformed query param: " + result);
    }

    // The character '+' is typically used as a replacement of a space ' ' in application/x-www-form-urlencoded.
    // Replace them with space ' ' to make them more human readable.
    // For spaces ' ' that are encoded in '%20', we don't need to do anything because 'decodeURIComponent' already decodes them.
    return result.replace(/\+/g, " ");
  }
};

/**
 * Gets the UTM campaign parameter values from a specified URL.
 * @param {string} url The URL to extract the UTM parameters from.
 * @returns {UTMApplicationData} An object containing UTM parameter key-value pairs.
 */
export const getUTMParameters = (url: string): UTMApplicationData => {
  const queryString: string = url.split("?")[1];
  const urlParams: string[] = queryString?.split("&") ?? [];
  const defaultUTMParam: UTMApplicationData = {
    utmCampaign: "",
    utmContent: "",
    utmMedium: "",
    utmSource: "",
    utmTerm: "",
  };

  const appData: UTMApplicationData = urlParams.reduce((params, param) => {
    const [pKey, pValue] = param.split("=");
    let pValueDecoded: string = pValue;
    const pKeyCamelCased = pKey.toLowerCase().replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

    if ((UTM_PARAMETERS as ReadonlyArray<string>).includes(pKeyCamelCased)) {
      // Decode the component from its raw URL source to a more human-readable string.
      try {
        pValueDecoded = decodeURIComponent(pValue);
      } catch (err) {
        pValueDecoded = pValue;
        console.error("Skipping decoding for malformed query param: " + pValue);
      }

      // Trim the url fragments because they are not a part of the value of the parameter.
      // e.g. url: www.mywebsite.com/somepage.aspx?utm_source=source&utm_medium=testMedium#productnamesection
      //      param: utm_medium
      //      output: testMedium
      pValueDecoded = pValueDecoded.split("#")[0];

      // The character '+' is typically used as a replacement of a space ' ' in application/x-www-form-urlencoded.
      // Replace them with space ' ' to make them more human readable.
      // For spaces ' ' that are encoded in '%20', we don't need to do anything because 'decodeURIComponent' already decodes them.
      pValueDecoded = pValueDecoded.replace(/\+/g, " ");

      params[pKeyCamelCased as keyof UTMApplicationData] = pValueDecoded;
    }

    return params;
  }, defaultUTMParam) as UTMApplicationData;

  return appData;
};
