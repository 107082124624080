/**
 * Wrap the given id as an object.
 *
 * @param id
 * @returns an object that has a value of ID
 */
export const wrapId = (id: string) => ({ value: id });

/**
 * Wrap the given list of id strings to a list of id objects.
 * @param list
 * @returns an array of wrapped valued IDs
 */
export const wrapIds = (list: Array<string>) => list.map(wrapId);
